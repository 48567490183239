const countriesParserMixin = {
  computed: {
    countryParser() {
      const parser = {
        AR: 'Argentina',
        BO: 'Bolivia',
        BR: this.$t('Brasil'),
        CL: 'Chile',
        CO: 'Colombia',
        CR: 'Costa Rica',
        CU: 'Cuba',
        DO: this.$t('República Dominicana'),
        EC: 'Ecuador',
        GD: this.$t('Granada'),
        GF: this.$t('Guayana Francesa'),
        GT: 'Guatemala',
        GY: this.$t('Guyana'),
        HN: 'Honduras',
        HT: 'Haiti',
        JM: 'Jamaica',
        MX: 'Mexico',
        NI: 'Nicaragua',
        PA: 'Panama',
        PE: 'Perú',
        PR: 'Puerto Rico',
        PY: 'Paraguay',
        SR: 'Surinam',
        SV: 'El Salvador',
        UY: 'Uruguay',
        VE: 'Venezuela',
        IN: 'India'
      }
      return parser
    }
  },
}

export default countriesParserMixin
