<template>
  <v-dialog
    v-model="showModal"
    max-width="900"
    @click:outside="closePrintHelper"
  >
    <v-card>
      <v-card-title class="text-h5">
      {{ $t('¿Problemas para imprimir los gráficos') + '?' }}
      </v-card-title>

      <v-card-text>
        <p>
          {{ $t('Al presionar el botón de imprimir y dirigirse a la interfaz de impresión, puede ver diferencias en el tamaño y disposicion de los gráficos y de la tipografía, según el navegador web que usted utilize (recomendamos utilizar Chrome o Firefox).') }}
        </p>
        <p>
          {{ $t('También puede haber variaciones segun el zoom que haya fijado en su navegador, se recomienda usar el valor por defecto (100%) al momento de presionar el botón de "imprimir gráficos".') }}
        </p>

        <p>
          {{ $t('En cualquiera de los casos, cuando presione el botón, se abrira el modal de impresión que es un componente que no depende de nuestra aplicación, sino del navegador.') }}
          {{ $t('En todos los navegadores encontrará aqui una versión distinta de esta funcionalidad, pero todos poseen algunas funciones útiles con las cuales puede darle mas personalización a la disposicion y tamaño de los elementos.') }}
          {{ $t('Por ejemplo, puede ajustar los márgenes de la página, seleccionar si desea imprimir en orientación vertical u horizontal, y decidir si desea imprimir los encabezados y pies de página del navegador.') }}
        </p>

        <p>
          {{ $t('Además, algunos navegadores ofrecen la opción de escalar el contenido de la página para que se ajuste al tamaño del papel. Esto puede ser útil si encuentra que el contenido de la página es demasiado grande o demasiado pequeño cuando se imprime.') }}
        </p>

        <v-card-subtitle> <b class="text-decoration-underline">  {{ $t('Ejemplo en Google Chrome') + ':' }} </b></v-card-subtitle>
        <p>
          <img style="width:100%; border: 1px solid #000" src="@/assets/images/printExample.gif" alt="Print example">
        </p>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="info"
          @click="closePrintHelper"
        >
          OK!
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose, mdiHelpCircleOutline, mdiPencilOutline, mdiTrashCanOutline, mdiPlus, mdiPrinter, mdiCommentMultipleOutline } from '@mdi/js'
import { props } from 'vue-prism-component'
export default {
  data: () => {
    return {
      showModal: false,
    }
  },
  props: {
    noOverflowHidden: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    openModal() {
      this.showModal = true
    },
    closePrintHelper() {
      this.showModal = false
      if(!this.noOverflowHidden) {
        this.$nextTick(function () {
          document.documentElement.classList.add('overflow-y-hidden');
        })
      }
    },
  },
  setup() {

    return {
      icons: {
        mdiClose,
        mdiHelpCircleOutline,
        mdiPencilOutline,
        mdiTrashCanOutline,
        mdiPlus,
        mdiPrinter,
        mdiCommentMultipleOutline
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
